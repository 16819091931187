import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import { AboutUs, Footer, Gallery, Header, Intro, Laurels } from './container';
import NavbarSecondary from './container/Navbar2/NavbarSecondary';
import Navbar from './container/Navbar/Navbar';
import Products from './container/Products/Products';
import './App.css'; 

const App = () => {
  const location = useLocation();

  const NavbarComponent = location.pathname === '/products' ? NavbarSecondary : Navbar;

  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <AboutUs />
              <Intro />
              <Laurels />
              <Gallery />
              <Footer />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <Products />
            </>
          }
        />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
