import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import M from '../assets/MemoraBlackPNG.png';
import MWhite from '../assets/MemoraWhitePNG.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import event from '../assets/event-planning.jpeg';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import logo2 from '../assets/logo2.png';
import vintage from '../assets/vintage.jpg';
import memoraLogo from '../assets/MemoraBlackTransparentBg.png';
import memoraLogoWhite from '../assets/MemoraFullWhitePNG.png';
import amblem from '../assets/amblem.png';

export default {
  amblem,
  memoraLogo,
  memoraLogoWhite,
  MWhite,
  bg,
  vintage,
  chef,
  M,
  logo2,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  event,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
};
